.react-calendar__navigation__prev-button, 
.react-calendar__navigation__next-button {
  border-radius: 3px;
  background-color: theme('colors.default[100]');
  color: theme('colors.default[600]')
}

.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation__next-button:hover {
  background-color: theme('colors.default[200]');
  color: theme('colors.default[900]')
}

.react-calendar__navigation__prev-button {
  padding: 7px 10px 7px 8px;
}

.react-calendar__navigation__next-button {
  padding: 7px 8px 7px 10px;
}

.react-calendar__month-view__days {
  padding: 4px;
}

.react-calendar__month-view__days button {
  border: 2px solid rgba(0, 0, 0, 0);
  padding: 16px 6px;
  margin: 4px 0px;
  font-size: 14px;
  font-weight: 400;
}

.react-calendar__month-view__days button.react-calendar__tile--rangeStart,
button.react-calendar__tile--rangeEnd {
  margin: 2px 0px;
  border-radius: 3px;
}

.react-calendar__month-view__days button:hover {
   /* border: 2px solid blue; */
  border-radius: 3px;
  margin: 2px 0px;
}

.react-calendar__month-view__days button:disabled {
  border: 0;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  /* font-size: theme.typography.subtitle1.fontSize;
        fontWeight: theme.typography.subtitle1.fontWeight; */
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__navigation {
  display: flex;
  align-items: center;
  padding: 1rem .5rem;
}
.react-calendar__navigation .react-calendar__navigation__label {
  flex-grow: 1;
  text-align: center;
  /* fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.h6.fontWeight, */
  pointer-events: none;
}
.react-calendar__navigation button:disabled {
  opacity: 0;
}
