.react-draggable {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  transform: none !important;
}

.custom-tooltip-connection {
  padding: 0 0 !important;
  border: none!important;
  background-color: transparent!important;
}