@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type='text']:focus, [type='email']:focus, [type="checkbox"]:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, select:focus, textarea:focus {
    border-color: theme('colors.primary.DEFAULT')!important; 
    --tw-ring-color: theme('colors.primary.DEFAULT')!important;
    box-shadow: none !important;
  }
}

@layer base {
  button:focus
  {
    outline: none!important;
  }

  /* button
  {
    font-family: PtRootUIMedium
  } */
}

@layer utilities {
  .disabled {
    opacity: .75;
    pointer-events: none;
  }
}

@layer base {
  @font-face {
    font-family: PtRootUI;
    src: url('assets/fonts/PT-Root-UI_Regular.woff') format('woff')
  }

  @font-face {
    font-family: PtRootUIMedium;
    src: url('assets/fonts/PT-Root-UI_Medium.woff') format('woff')
  }
}

@layer base {
  body {
    /* font-family: PtRootUI; */
   /* font-family: PtRootUIMedium; */
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }
}

/* TODO add tailwind-scrollbar */

.scroll-hide::-webkit-scrollbar {
  width: 0px;
  height: 0px;

}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: theme('colors.default[300]');
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: theme('colors.default[400]');
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
